

.swiper-container {
		width: 100%;

       img {
            display: flex
            justify-content: center
            align-items: flex-start
            max-height 700px
            object-fit: contain
            @media screen and (max-width: 960px){
                max-height 450px
            }
        }
}



.thumb-gallery  {
  height: 100%;
  max-height: 700px
  background-color: white;
  width: 100%
  display: flex
  flex-wrap: wrap
  justify-content: center
  max-width: 100%
  margin-bottom: -25px
  @media screen and (max-width: 960px){
      min-height: 500px;
      height: auto;
  }
}

::v-deep .swiper-slide-active {
  display: flex;
  justify-content: center;
}

.gallery {
  width: 100%;
  max-width: 620px;
  margin: 40px auto;

  &-slider {
    // width: 100%;
    width: 20%;
    height: auto;
    margin: 0 0 10px 0;

    .swiper-slide {
      width: auto;
      height: 400px;

      img {
        display: block;
        width: 85px !important;
        height: 85px !important;
        margin: 0 auto;
      }
    }
  }

  &-thumbs {
    width: 100%;
    padding: 0;
    overflow: hidden;
    @media small {
      max-width: 375px;
    }

    @media tablet {
      max-width: 400px;
    }

    .swiper-slide {
      width: 75px;
      height: 75px;
      padding-bottom: 1rem;
      text-align: center;
      overflow: hidden;
      opacity: 1;
      @media small {
        width: auto;
        height: 75px;
      }
      img {
          // width: 65px !important;
          // height: 65px !important;
          object-fit: contain
          border-radius: 8px;
          border: 1px solid $color-bnn-gray-light;

          @media small {
            width: auto !important;
            height: 65px !important;
          }


      }

      &-active {
        opacity: 1;
        img {
          border: 1px solid $color-bnn-border-2;
        }
      }

      img {
        width: auto;
        height: 100%;
      }
    }
  }
}

::v-deep .swiper-pagination-bullet-active {
  width: 24px !important
  background: $color-bnn-gray-medium 0% 0% no-repeat padding-box !important;
  border-radius: 2px !important;
}

::v-deep .swiper-pagination-bullet {
  width: 16px
  background: $color-bnn-gray-light 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity 1
}

.swiper-pagination-preview-product {
  display: none;
}

.gallery-thumbs {
      display: block;
}


@media screen and (max-width: 960px){
  .gallery-thumbs {
        display: block;
        margin-bottom: 3.5rem;
  }

  .swiper-pagination-preview-product {
      display: block;
      bottom: 0;
  }

  .thumb-gallery  {
    height: auto
    max-width: max-content
  }
}

img {
  width:auto !important;
}

.gallery-square-layout{

  .thumb-gallery{
      width 100%
      max-width 100%
      height auto
      min-height fit-content !important

      .swiper-container.gallery-top{
          width 100%
          height auto
          max-width 500px
          max-height 500px
          aspect-ratio 1 / 1

          .swiper-slide{
              width 100%
              height 100%
          }

          img {
            width 100% !important
            height 100% !important
            max-height 100%
            object-fit contain
            object-position: center;
          }
      }

      .swiper-container.gallery-thumbs {
        height: fit-content

        .swiper-slide{
          aspect-ratio 1 / 1
          width: 100%
          height fit-content
          padding 2px

          &::before {
            float: left;
            padding-top: 100%;
            content: "";
          }

          &::after {
            display: block;
            content: "";
            clear: both;
          }
        }

        img {
          display: inline-block !important
          width 100% !important
          height auto !important
          max-height 100% !important
          max-height 100%
          object-position: center
          object-fit: cover
        }

      }
  }
}
.aion-ev.cart-pre-order,
.aion-ev.remaining-cart-pre-order{
.thumb-gallery{
    .swiper-container.gallery-top{
        img {
          object-fit contain !important
        }
    }
}
}
