
.loading-screen
  position fixed
  z-index 10
  top 0
  display flex
  align-items center
  justify-content center
  height 100dvh
  width 100dvw
  background-color rgba(255,255,255,0.6)
